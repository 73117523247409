import React, { Component, Fragment } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Section } from '../components/section'
import { HalfSpacedHr } from '../components/spaced-hr'
import email1 from '../images/saba-email.png'
import twitter from '../images/saba-twitter.png'
import facebook from '../images/saba-facebook.png'
import phone1 from '../images/saba-phone.png'
import { Button, Label, Form, FormGroup, Input, Row } from 'reactstrap'
import colors from '../styles/colors'


const IconImg =styled.img`
width: 50px;
    margin: .2rem .5rem;
`

const PageTitleSection = styled.div`
  background: #0094d9;
  padding: 20px 24px;
  margin: 0 -25px 25px;
  @media (max-width: 767px) {
    padding: 20px 24px 10px;
    display: flex;
    background: #0094d9;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    height: 100%;
    margin: 16px -12px 0;
  }
`

const PageSection = styled.div`
  padding: 1rem 0;
  // background: #efefef;
  margin: 0 auto;
  max-width: 55rem;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

const Section1 = styled.div`
  max-width: 70rem;
  width: 100%;
  margin: 0 auto;
`

const Row1 = styled.div`
  max-width: 80rem;
  width: 100%;
  margin: 0rem auto;
  padding: 1.5rem 24px 0 24px;

  @media (max-width: 768px) {
    margin: 0rem auto 3rem;
    padding: 0;
  }
`

const H1 = styled.h1`
  margin: 0;
  color: #fff;
`

const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 80rem;
  flex-direction: row-reverse;
  width: 100%;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const StyledForm = styled(Form)`
  width: 60%;
  padding: 25px;
  background: #efefef;
  /* border-radius: 25px; */
  /* background: #ffbd11; */

  @media (max-width: 768px) {
    padding: 15px;
    background: #efefef;
    max-width: 100%;
    width: 100%;
  }

  button {
    background-color: ${colors.theme.secondary};
    border-color: ${colors.theme.main};
    &:hover {
      background-color: ${colors.theme.fourth};
      border-color: ${colors.theme.lightmain};
    }
  }
`
const ContactInfo = styled.div`
  /* background-color: rgba(0,0,0,0.08); */
  /* width: 50%; */
  /* max-width: 535px; */
  height: 100%;
  padding: 20px 25px;
  /* margin-left: 6px; */
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  /* // align-items: center; */
  /* background: #0071a6; */

  h5 {
    text-align: left;
  }

  .contact-item {
    display: flex;
    align-items: center;
    span {
      color: #efefef;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.payload {
        color: ${colors.theme.darkaccent};
        // border-bottom: 1px solid ${colors.theme.accent};
        a {
          text-decoration: none;
          color: ${colors.theme.darkaccent};
          &:hover {
            color: ${colors.theme.accent};
          }
        }
      }
    }
    svg {
      margin: 6px 10px;
      &.phone {path{fill: #10435e}}
      &.email {path{fill: #10435e}}
      &.fb {path{fill: #10435e}}

    }
  }

  @media (max-width: 768px) {
    margin: 0;
    margin-top: 40px;
    // border-top: 1px solid ${colors.border.light};
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
`

const FormGroupInline = styled.div`
  display: flex;
  justify-content: space-between;
`

const InlineFormGroup2 = styled(FormGroup)`
  width: 48%;
  display: inline-block;
`

const InlineFormGroup3 = styled(FormGroup)`
  width: 32.5%;
  display: inline-block;
`

// const stateOptions = [
//   'AL',
//   'AK',
//   'AS',
//   'AZ',
//   'AR',
//   'CA',
//   'CO',
//   'CT',
//   'DE',
//   'DC',
//   'FM',
//   'FL',
//   'GA',
//   'GU',
//   'HI',
//   'ID',
//   'IL',
//   'IN',
//   'IA',
//   'KS',
//   'KY',
//   'LA',
//   'ME',
//   'MH',
//   'MD',
//   'MA',
//   'MI',
//   'MN',
//   'MS',
//   'MO',
//   'MT',
//   'NE',
//   'NV',
//   'NH',
//   'NJ',
//   'NM',
//   'NY',
//   'NC',
//   'ND',
//   'MP',
//   'OH',
//   'OK',
//   'OR',
//   'PW',
//   'PA',
//   'PR',
//   'RI',
//   'SC',
//   'SD',
//   'TN',
//   'TX',
//   'UT',
//   'VT',
//   'VI',
//   'VA',
//   'WA',
//   'WV',
//   'WI',
//   'WY',
// ]

// const bathOptions = ['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5+']

// const bedOptions = ['0 (Studio)', '1', '2', '3', '4', '5', '6+']

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      // address: '',
      // city: '',
      // state: 'NJ',
      // zipcode: '',
      // numBed: bedOptions[0],
      // numBath: bathOptions[0],
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleSubmit(event) {}

  render() {
    const email = 'info@somaliamericanbusiness.org'

    return (
      <Layout>
        <SEO title="Contact Somali American Business Association" />
        <Row1>
          <PageTitleSection>
            <H1>Contact Us</H1>
          </PageTitleSection>
          <HalfSpacedHr />
          <PageSection>
            <p>
              You can email us with any questions or feedback by filling out our
              contact form below.
            </p>
          </PageSection>
          <PageSection>
            <ContactWrapper>
              <ContactInfo>
                <h5>Questions or concerns?</h5>
                {/* <div className="contact-item">
                  <IconImg src={phone1} alt="Phone Icon" />
                  <span>Text us at</span>&nbsp;
                  <span className="payload">+1 (612) 555-5555</span>
                </div> */}
                <div className="contact-item">
                  <IconImg src={email1} alt="Email Icon" />
                  <span>Email us at</span>&nbsp;
                  <span className="payload">
                    <a href="mailto:{email}">{email}</a>
                  </span>
                </div>

                <div className="contact-item">
                  <IconImg src={facebook} alt="Email Icon" />
                  <span>Like us on</span>&nbsp;
                  <span className="payload">
                    <a
                      target="_blank"
                      href="http://facebook.com/BusinessSomali"
                    >
                      Facebook
                    </a>
                  </span>
                </div>
                <div className="contact-item">
                  <IconImg src={twitter} alt="Email Icon" />

                  <span>Follow us on</span>&nbsp;
                  <span className="payload">
                    <a target="_blank" href="http://twitter.com/BusinessSomali">
                      Twitter
                    </a>
                  </span>
                </div>
              </ContactInfo>

              <StyledForm
                action={`https://formspree.io/${email}`}
                method="POST"
              >
                <FormGroup>
                  {/* <Label for="name">Name</Label> */}
                  <Input
                    type="text"
                    name="fullname"
                    id="name"
                    placeholder="Your Name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  {/* <Label for="email">Email Address</Label> */}
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  {/* <Label for="phone">Phone Number</Label> */}
                  <Input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="exampleText">Property Address</Label>
                  <Input
                    type="textarea"
                    name="address"
                    id="address"
                    placeholder=""
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroupInline>
                  <InlineFormGroup3>
                    <Label for="exampleText">City</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      placeholder=""
                      value={this.state.city}
                      onChange={this.handleChange}
                    />
                  </InlineFormGroup3>
                  <InlineFormGroup3>
                    <Label>State</Label>
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      value={this.state.state}
                      onChange={this.handleChange}
                    >
                      {stateOptions.map((v, i) => (
                        <option key={i}>{v}</option>
                      ))}
                    </Input>
                  </InlineFormGroup3>
                  <InlineFormGroup3>
                    <Label for="exampleText">Zip Code</Label>
                    <Input
                      type="number"
                      name="zipcode"
                      id="zipcode"
                      placeholder=""
                      value={this.state.zipcode}
                      onChange={this.handleChange}
                    />
                  </InlineFormGroup3>
                </FormGroupInline>
                <FormGroupInline>
                  <InlineFormGroup2>
                    <Label>Number of Bedrooms</Label>
                    <Input
                      type="select"
                      name="numBed"
                      id="numBed"
                      value={this.state.numBed}
                      onChange={this.handleChange}
                    >
                      {bedOptions.map((v, i) => (
                        <option key={i}>{v}</option>
                      ))}
                    </Input>
                  </InlineFormGroup2>
                  <InlineFormGroup2>
                    <Label>Number of Bathrooms</Label>
                    <Input
                      type="select"
                      name="numBath"
                      id="numBath"
                      value={this.state.numBath}
                      onChange={this.handleChange}
                    >
                      {bathOptions.map((v, i) => (
                        <option key={i}>{v}</option>
                      ))}
                    </Input>
                  </InlineFormGroup2>
                </FormGroupInline> */}
                <FormGroup>
                  {/* <Label for="exampleText">Message</Label> */}
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    value={this.state.message}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                {/*<input />*/}
                <Button
                  type="submit"
                  size="lg"
                  block
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </StyledForm>
            </ContactWrapper>
          </PageSection>
        </Row1>
      </Layout>
    )
  }
}

export default Contact
